import { Component, OnInit } from '@angular/core';

@Component({
  selector: "home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"]
})
export class HomeComponent implements OnInit {
  appState: number = 0;

  constructor() {}

  ngOnInit() {
    this.appState = 0;
  }

  isStart(): boolean {
    return this.appState == 0;
  }

  isMultipleChoice(): boolean {
    return this.appState == 1;
  }

  isSwipe(): boolean {
    return this.appState == 2;
  }

	isComplete(): boolean {
		return this.appState == 3;
	}

  setState(state: number) {
    this.appState = state;
  }
}
