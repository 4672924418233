import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpModule } from "@angular/http";
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import * as Hammer from 'hammerjs';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

import { ChatModule } from "./chat/chat.module";

export class MyHammerConfig extends HammerGestureConfig {
	overrides = <any>{
		// override hammerjs default configuration
		'swipe': { direction: Hammer.DIRECTION_ALL }
	}
}

import {
  MatButtonModule,
  MatCheckboxModule,
  MatCardModule
} from "@angular/material";

import { AppComponent } from './app.component';
import { QuestionChoiceComponent } from './question-choice/question-choice.component';
import { QuestionSwipeComponent } from './question-swipe/question-swipe.component';
import { HomeComponent } from './home/home.component';
import { HammerCardComponent } from './hammer-card/hammer-card.component';
import { HammertimeDirective } from './hammertime.directive';

const appRoutes: Routes = [
 	{ path: "home", component: HomeComponent },
  	{ path: "**", component: HomeComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    QuestionChoiceComponent,
    QuestionSwipeComponent,
    HomeComponent,
	HammerCardComponent,
	HammertimeDirective,
  ],
  imports: [
	  BrowserModule,
	  ChatModule,
		BrowserAnimationsModule,
	  HttpModule,
	  MatButtonModule,
	  MatCheckboxModule,
	  	MatCardModule,
		RouterModule.forRoot(
		appRoutes,
		{ enableTracing: false } // <-- true for debugging purposes only
		)
  ],
	providers: [{
		provide: HAMMER_GESTURE_CONFIG,
		useClass: MyHammerConfig
	}],
  bootstrap: [AppComponent]
})
export class AppModule {}
