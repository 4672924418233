import { Component, OnInit } from '@angular/core';

@Component({
  selector: "question-swipe",
  templateUrl: "./question-swipe.component.html",
  styleUrls: ["./question-swipe.component.css"]
})
export class QuestionSwipeComponent implements OnInit {
  
	SWIPE_ACTION = { LEFT: "swipeleft", RIGHT: "swiperight" };

	options = [
		{ text: "Right Option", visible: false },
		{ text: "Swipe right for Red. Swipe left for Blue", visible: true },
		{ text: "Left Option", visible: false }
	];

	constructor() {}

	ngOnInit() {}

	swipe(currentIndex: number, action: string = this.SWIPE_ACTION.RIGHT) {
		console.log(currentIndex);
		if (currentIndex > this.options.length || currentIndex < 0) return;

		let nextIndex = 0;

		// next
		if (action === this.SWIPE_ACTION.RIGHT) {
			const isLast = currentIndex === this.options.length - 1;
			nextIndex = isLast ? 0 : currentIndex + 1;
		}

		// previous
		if (action === this.SWIPE_ACTION.LEFT) {
			const isFirst = currentIndex === 0;
			nextIndex = isFirst ? this.options.length - 1 : currentIndex - 1;
		}

		// toggle avatar visibility
		this.options.forEach((x, i) => (x.visible = i === nextIndex));
	}
}
