import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'question-choice',
  templateUrl: './question-choice.component.html',
  styleUrls: ['./question-choice.component.css']
})
export class QuestionChoiceComponent implements OnInit {

	chosen = 0;

	constructor() { }

	ngOnInit() {
	}

	onOptionClicked(choice: number) {
		this.chosen = choice;
	}

	shouldShow(choice: number) {

		if (this.chosen == 0) {
			return true;
		}

		return this.chosen == choice;
	}
}
