import { Injectable } from '@angular/core';
import { Http, Headers } from "@angular/http";
import "rxjs/add/operator/map";
// import { DialogFlow } from 'dialogflow';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

import { environment } from "../../environments/environment";


export class Message {
	constructor(public content: string, public sentBy: string) { }
}

@Injectable()
export class ChatService {
  readonly token = environment.dialogflow.pollenClientKey;
  //   readonly client = new DialogFlow({ accessToken: this.token });
  private baseURL: string = "https://api.dialogflow.com/v1/query?v=20150910";

  conversation = new BehaviorSubject<Message[]>([]);

  constructor(private http: Http) {}

  // Adds message to source
  update(msg: Message) {
    this.conversation.next([msg]);
  }

	public converse(msg: string) {

		const userMessage = new Message(msg, "user");
		this.update(userMessage);

		// return this.client.textRequest(msg).then(res => {
		//   const speech = res.result.fulfillment.speech;
		//   const botMessage = new Message(speech, "bot");
		//   this.update(botMessage);
		// });

		let data = {
			query: msg,
			lang: "en",
			sessionId: "12345"
		};

		return this.http
			.post(`${this.baseURL}`, data, { headers: this.getHeaders() })
			.subscribe(res => {
				var response = res.json();
				
				const speech = response.result.fulfillment.speech;
               	const botMessage = new Message(speech, "Pollen");
				this.update(botMessage);
				   
             	return res.json();
           });
	}

  public getHeaders() {
    let headers = new Headers();
    headers.append("Authorization", `Bearer ${this.token}`);
    return headers;
  }
}
