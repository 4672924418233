import { Component } from '@angular/core';

import { trigger, keyframes, animate, transition } from '@angular/animations';
import * as kf from './keyframes';

@Component({
	selector: 'hammer-card',
	templateUrl: './hammer-card.component.html',
	styleUrls: ['./hammer-card.component.sass'],
	animations: [
		trigger('cardAnimator', [
			transition('* => wobble', animate(1000, keyframes(kf.wobble))),
			transition('* => swing', animate(1000, keyframes(kf.swing))),
			transition('* => jello', animate(1000, keyframes(kf.jello))),
			transition('* => zoomOutRight', animate(1000, keyframes(kf.zoomOutRight))),
			transition('* => slideOutLeft', animate(300, keyframes(kf.slideOutLeft))),
			transition('* => rotateOutUpRight', animate(1000, keyframes(kf.rotateOutUpRight))),
			transition('* => flipOutY', animate(1000, keyframes(kf.flipOutY))),
		])
	]
})
export class HammerCardComponent {

	animationState: string;

	startAnimation(state) {
		console.log(state)
		if (!this.animationState) {
			this.animationState = state;
		}
	}

	resetAnimationState() {
		this.animationState = '';
	}

}